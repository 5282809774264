// Theme1Routes.js
import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "./Theme1Components/Home";
import Services from "./Theme1Components/Services";
import Gallery from "./Theme1Components/Gallery";
import AboutContainer from "./Theme1Components/AboutContainer";
import ForgetPassword from "./Theme1Components/ForgetPassword";
import Signup from "./Theme1Components/Signup";
import Appointments from "./Theme1Components/Appointments";
import Complaints from "./Theme1Components/Complaints";
import Contact from "./Theme1Components/Contact";
import EHealthContainer from "./Theme1Components/EHealthContainer";
import Reviews from "./Theme1Components/Reviews";
import LoginDashboard from "./Theme1Components/LoginDashboard";
import Login from "./Theme1Components/Login";
import ServicesList from "./Theme1Components/ServicesList";
import Blogs from "./Theme1Components/Blogs";
import News from "./Theme1Components/News";
import BlogDetail from "./Theme1Components/BlogDetail";
import AuthorDetail from "./Theme1Components/AuthorDetail";
import NewsDetail from "./Theme1Components/NewsDetail";
import Totkey from "./Theme1Components/Theme1DashboardComponents/Totkey";
import EHealthAppointment from "./Theme1Components/HospitalLogin/EHealthAppointment";
import FruitsDetail from "./Theme1Components/Theme1DashboardComponents/FruitsDetail";
import BasicAppointment from "./Theme1Components/Theme1DashboardComponents/ProfileTabs/RecentTabs/BasicAppointment";
import Doctors from "./Theme1Components/HospitalFolder/Doctors";
import CareerDetail from "./Theme1Components/HospitalFolder/CareerDetail";
import Careers from "./Theme1Components/HospitalFolder/Careers";
import DropCV from "./Theme1Components/HospitalFolder/DropCV";
import Facilities from "./Theme1Components/HospitalFolder/Facilities";
import DoctorDetails from "./Theme1Components/HospitalFolder/DoctorDetails";
import PatientPortal from "./Theme1Components/DoctorFolder/PatientPortal";
import HospitalClinic from "./Theme1Components/DoctorFolder/HospitalClinic";
import "./Theme1Components/themeassets/css/plugins/bootstrap.min.css";
import "./Theme1Components/themeassets/css/plugins/fontawesome.min.css";
import "./Theme1Components/themeassets/css/plugins/animate.css";
import "./Theme1Components/themeassets/css/plugins/slick.css";
import "./Theme1Components/themeassets/css/plugins/odometer.css";
import "./Theme1Components/themeassets/css/plugins/lightgallery.min.css";
import "./Theme1Components/themeassets/css/plugins/jquery.timepicker.min.css";
import "./Theme1Components/themeassets/css/plugins/jquery-ui.css";
import "./Theme1Components/themeassets/css/plugins/select2.min.css";
import { useSelector } from "react-redux";
import { Circles } from "react-loader-spinner";

function Theme1Routes({ isData, setIsdata, patientLogin }) {
  const sitedata = useSelector((store) => store.global.loginData);
  import("./Theme1Components/themeassets/css/style.css").then(() => {});

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  // if (sitedata?.site_type == "hospitals") {
  //   import('./components/Main.css').then(() => {
  //   });
  // } else if (sitedata?.site_type == "doctors") {
  //   import('./components/Doctor.css').then(() => {
  //   });
  // }

  // useEffect(() => {
  //   if (sitedata) {
  //     document.title = sitedata?.site_title || "Welcome - ClinTa Web Builder";
  //     const favicon = document.querySelector("link[rel='icon']");
  //     if (favicon) {
  //       favicon.href = sitedata?.site_logo || "/assets/img/clinta.ico";
  //     }
  //   }
  // }, [sitedata]);

  const [faviconUrl, setFaviconUrl] = useState("");
  useEffect(() => {
    if (sitedata && sitedata?.site_logo) {
      const base64Url = `${sitedata?.site_logo}`;
      setFaviconUrl(base64Url);
    }
  }, [sitedata]);

  useEffect(() => {
    if (faviconUrl) {
      const faviconLink = document.querySelector("link[rel='icon']");
      if (faviconLink) {
        faviconLink.href = faviconUrl;
      } else {
        const newFaviconLink = document.createElement("link");
        newFaviconLink.rel = "icon";
        newFaviconLink.href = faviconUrl;
        document.head.appendChild(newFaviconLink);
      }
    }
  }, [faviconUrl]);

  const themeColor = sitedata?.theme_color;

  if (themeColor) {
    import(`./Theme1Components/${themeColor}`)
      // import("./Theme1Components/red.css").then(() => {})
      // import("./Theme1Components/blue.css").then(() => {})
      // import("./Theme1Components/black.css").then(() => {})
      // import("./Theme1Components/gold.css").then(() => {})
      .then((module) => {
        console.log("Module loaded:", module);
      })
      .catch((error) => {
        console.error("Failed to load module:", error);
      });
  } else {
    import("./Theme1Components/blue.css").then(() => {});
    console.error("theme_color is undefined or null");
  }

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{
          height: "100vh",
          width: "100%",
          position: "absolute",
          top: 0,
          left: 0,
        }}
      >
        <Circles
          height="80"
          width="80"
          color="#000"
          ariaLabel="circles-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  }

  return (
    <Routes>
      <Route
        path="/"
        element={<Home isData={isData} setIsdata={setIsdata} />}
      />
      <Route path="/services" element={<Services />} />
      <Route path="/doctors" element={<Doctors />} />
      <Route path="/gallery" element={<Gallery />} />
      <Route path="/careers" element={<Careers />} />
      <Route path="/career-detail/:id" element={<CareerDetail />} />
      <Route path="/drop-cv" element={<DropCV />} />
      <Route path="/about" element={<AboutContainer />} />
      <Route path="/facilities" element={<Facilities />} />
      <Route path="forget-password/:slug" element={<ForgetPassword />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/appointment" element={<Appointments />} />
      <Route path="/complaints" element={<Complaints />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/e_health" element={<EHealthContainer />} />
      <Route path="/reviews" element={<Reviews />} />
      <Route path="/doctor/:id/:slug" element={<DoctorDetails />} />
      {patientLogin ? (
        <Route path="patient/*" element={<LoginDashboard />} />
      ) : (
        <Route path="patient/*" element={<Navigate to="/" />} />
      )}
      <Route path="/patient-portal" element={<PatientPortal />} />
      <Route path="/hospital-clinic/:slug" element={<HospitalClinic />} />
      <Route path="/login" element={<Login />} />
      <Route
        path="/patient/e_health_appointment"
        element={<EHealthAppointment />}
      />
      <Route path="/patient/appointments" element={<BasicAppointment />} />
      <Route path="/service/:id/:slug" element={<ServicesList />} />
      <Route path="fruit-detail" element={<FruitsDetail />} />
      <Route path="totkey" element={<Totkey />} />
      <Route path="/blogs" element={<Blogs />} />
      <Route path="/news" element={<News />} />
      <Route path="/blog/detail/:slug" element={<BlogDetail />} />
      <Route path="/author/detail/:slug" element={<AuthorDetail />} />
      <Route path="/news/detail/:slug" element={<NewsDetail />} />
    </Routes>
  );
}

export default Theme1Routes;
