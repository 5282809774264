import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { capitalize } from "@mui/material";
import { toast } from "react-toastify";
import { SendPostRequest } from "../../http";
import Captcha from "../Captcha/Captcha";
import { formatTime } from "../../Helper/Helper";
import { Table } from "react-bootstrap";
import HeaderWrapper from "../Header/HeaderWrapper";
import FooterWrapper from "../Footer/FooterWrapper";

function HospitalClinic() {
  const sitedata = useSelector((store) => store.global.loginData);
  const patientLogin = useSelector((state) => state.patient.patientLogin);
  const { slug } = useParams();
  const navigate = useNavigate();
  const [hospitalDetail, setHospitalDetail] = useState([]);
  const [selectedHospital, setSelectedHospital] = useState(null);
  const [gender, setGender] = useState(null);
  const [shift, setShift] = useState(null);

  const [relation, setRelation] = useState(null);
  const [selectedDep, setSelectedDep] = useState(null);

  const [inputValues, setInputValues] = useState({});

  const [hospitalSlots, setHospitalSlots] = useState([]);
  const [displayedValue, setDisplayedValue] = useState(null);
  const [bookingDay, setBookingDay] = useState("");

  const todayDate = new Date().toISOString().split("T")[0];

  // get hospital slots
  useEffect(() => {
    const getHospitalSlots = async () => {
      const date = inputValues.appointment_date;
      const type = sitedata.site_type;
      let allInputs;

      try {
        allInputs = {
          type,
          doct_uid: sitedata.site_id,
          clinic_id: selectedHospital?.value?.info?.uid,
          date,
        };
        if (date) {
          const res = await SendPostRequest("hospitals/slots", allInputs);
          setHospitalSlots(res.data);
        }
      } catch (error) {
        console.error("Error fetching doctor's slots:", error);
      }
    };
    getHospitalSlots();
  }, [inputValues.appointment_date, selectedHospital]);

  const [shiftOptions, setShiftOptions] = useState([]);

  useEffect(() => {
    const newShiftOptions = [];

    // Check if hospitalSlots is empty
    if (
      Object.keys(hospitalSlots).length === 0 &&
      inputValues.appointment_date
    ) {
      // If empty, set default values

      if (bookingDay !== "") {
        const selectedDayObject = apointmentDays[bookingDay];
        const Morning = "Morning";
        const Evening = "Evening";
        if (selectedDayObject[Morning] && selectedDayObject[Evening]) {
          newShiftOptions.push({ value: "evening", label: "Evening" });
          newShiftOptions.push({ value: "morning", label: "Morning" });
        } else if (selectedDayObject[Morning]) {
          newShiftOptions.push({ value: "morning", label: "Morning" });
        } else if (selectedDayObject[Evening]) {
          newShiftOptions.push({ value: "evening", label: "Evening" });
        }
      }
    } else {
      // If not empty, populate shift options from hospitalSlots
      for (const key in hospitalSlots) {
        if (hospitalSlots.hasOwnProperty(key)) {
          newShiftOptions.push({ value: key, label: hospitalSlots[key] });
        }
      }
    }

    setShiftOptions(newShiftOptions);
    setDisplayedValue(null);
  }, [hospitalSlots]);

  const formatDate = (inputDate) => {
    const dateObject = new Date(inputDate);

    const year = dateObject.getFullYear();
    const month = dateObject.getMonth() + 1;
    const day = dateObject.getDate();

    const formattedDate = `${year}-${month < 10 ? "0" + month : month}-${
      day < 10 ? "0" + day : day
    }`;

    return formattedDate;
  };

  const handleContactNumber = (e) => {
    let val = e.target.value;
    if (val.length <= 14) {
      setInputValues((prevValues) => ({
        ...prevValues,
        contact_number: val,
      }));
    }
    return;
  };

  const handleAllInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "appointment_date") {
      const formattedDate = formatDate(value);
      const selectedDate = new Date(formattedDate);
      const dayOfWeek = selectedDate.toLocaleDateString("en-US", {
        weekday: "long",
      }); // Get the day of the week as a string (e.g., "Monday")
      setBookingDay(dayOfWeek);

      setInputValues((prevValues) => ({
        ...prevValues,
        appointment_date: formattedDate,
      }));
    } else {
      setInputValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }
  };

  const [apointmentDays, setApointmentDays] = useState([]);
  const getDoctorsDetail = async () => {
    try {
      const response = await SendPostRequest("doctors/schedule");
      const hospitals = response?.data?.hospitals;

      if (hospitals && hospitals?.length > 0) {
        setHospitalDetail(hospitals);
        setApointmentDays(hospitals[slug].schedule);
        setSelectedHospital({
          label: hospitals[slug].info.title,
          value: hospitals[slug],
        });
      }
    } catch (error) {
      console.error("Error fetching doctor's schedule:", error);
    }
  };

  useEffect(() => {
    if (patientLogin) {
      setInputValues({
        patient_name: patientLogin?.fullname,
        email: patientLogin?.email,
        contact_number: patientLogin?.contact_num,
      });
    }

    const title = sitedata?.site_title || "";
    document.title = capitalize(title) + " :: Doctor Details - ClinTa";
    window.scrollTo(0, 0);
    getDoctorsDetail();
    handleRefresh();
  }, []);
  const handleHospitalChange = (selectedOption) => {
    setSelectedHospital(selectedOption);
  };

  const genderOptions = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
  ];

  const handleReasonChange = (selectedOption) => {
    setRelation(selectedOption.value);
  };

  const handleGenderChange = (selectedOption) => {
    setGender(selectedOption.value);
  };

  const handleShiftChange = (selectedOption) => {
    setShift(selectedOption.value);
    setDisplayedValue(selectedOption?.label);
  };

  const [captcha, setCaptcha] = useState("");
  const [input_captcha, setInputCaptcha] = useState("");
  const [check_captcha, setCheckCaptcha] = useState("");
  const [loading, setLoading] = useState(false);

  const textColorClass =
    check_captcha === "Captcha Verified" ? "text-success" : "text-danger";

  const handleInputChange = (e) => {
    setInputCaptcha(e.target.value);
  };

  const handleRefresh = () => {
    const newCaptcha = generateRandomCaptcha();
    setCaptcha(newCaptcha);
  };

  const reasonOptions = [
    { value: "son-of", label: "Son Of" },
    { value: "daughter-of", label: "Daughter Of" },
    { value: "father-of", label: "Father Of" },
    { value: "mother-of", label: "Mother Of" },
    { value: "husband-of", label: "Husband Of" },
    { value: "wife-of", label: "Wife Of" },
  ];

  const generateRandomCaptcha = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const captchaLength = 6;
    let newCaptcha = "";

    for (let i = 0; i < captchaLength; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      newCaptcha += characters.charAt(randomIndex);
    }

    return newCaptcha;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!inputValues.patient_name) {
      toast.error("Enter patient name");
      return;
    } else if (!gender) {
      toast.error("Select Gender");
      return;
    } else if (
      inputValues.email &&
      (!inputValues.email.includes(".") || !inputValues.email.includes("@"))
    ) {
      toast.error("Invalid email");
      return;
    } else if (!inputValues.contact_number) {
      toast.error("Enter your contact number");
      return;
    } else if (
      inputValues.contact_number.length > 14 ||
      inputValues.contact_number.length < 10
    ) {
      toast.error("Invalid Number");
      return;
    } else if (!inputValues.appointment_date) {
      toast.error("Enter date");
      return;
    } else if (!shift) {
      toast.error("Enter your shift");
      return;
    } else if (!input_captcha) {
      toast.error("Enter Captcha");
      return;
    } else if (captcha === input_captcha) {
      setCheckCaptcha("Captcha Verified");
    } else {
      setCheckCaptcha("Captcha failed");
    }
    if (captcha == input_captcha) {
      setLoading(true);
      setCheckCaptcha("Captcha Verified");

      try {
        const myallInputs = {
          ...inputValues,
          gender,
          shift,
          relation,
          // doctor_id: selectedHospital?.info?.uid,
          // doctor_id: hospitalDetail[1]?.info?.uid,
          doctor_id: sitedata.site_id,
        };

        const response = await SendPostRequest(
          "doctors/appointment_form",
          myallInputs
        );

        if (response.status === "success") {
          setInputValues({
            patient_name: patientLogin ? patientLogin.fullname : "",
            email: patientLogin ? patientLogin.email : "",
            contact_number: patientLogin ? patientLogin.contact_num : "",
            age: "",
            relation_name: "",
            appointment_date: "",
            remarks: "",
          });

          setDisplayedValue(null);
          handleRefresh();
          setRelation({ value: "" });
          setShift({ value: "" });
          setGender({ value: "" });
          setInputCaptcha("");
          setCheckCaptcha("");
        }
        setLoading(false);

        if (response.status === "success") {
          toast.success(response.message);
          // navigate("/home");
        } else {
          toast.error(response.message);
        }
      } catch (error) {
        setLoading(false);
        toast.error("Failed");
        console.log("error", error);
      }
    } else {
      setCheckCaptcha("Captcha failed");
    }
  };

  const [backgroundImage, setBackgroundImage] = useState("");
  useEffect(() => {
    let themeColor;
    if (sitedata) {
      themeColor = sitedata?.theme_color?.split(".css").join("");
    } else {
    }

    setBackgroundImage(`/assets/img/doctors/banner_bg_${themeColor}.svg`);
  }, [sitedata?.theme_color]);
  return (
    <div className="page-wrapper-inner">
      <div class="page-wrapper-inner">
        <HeaderWrapper />
        <div className="page-title-wrap typo-white">
          <div
            className="page-title-wrap-inner section-bg-img"
            style={{
              backgroundImage: "url(/assets/images/bg/page-title-bg.jpg)",
            }}
          >
            <span className="black-overlay"></span>
            <div className="container">
              <div className="row ">
                <div className="col-md-12">
                  <div className="page-title-inner">
                    {/* <div id="breadcrumb" className="breadcrumb mb-1 mb-lg-2">
                  <a href="index.html" className="theme-color">
                    Home
                  </a>
                  <span className="current">Portfolio Extended</span>
                </div> */}
                    <h1 className="page-title mb-0">Hospital/Clinta</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="content-wrapper pad-none">
          <div className="content-inner">
            <section
              id="single-portfolio"
              className="single-portfolio pad-bottom-90 full-dark"
            >
              <div className="container">
                <div className="row relative">
                  <div className="col-lg-6 mb-3 mb-lg-0">
                    <div className="title-wrap">
                      <div className="section-title">
                        <h2 className="title text-uppercase mb-0">
                          {hospitalDetail[0]?.info
                            ? hospitalDetail[0]?.info?.Name
                            : "Doctor Detail"}
                          {/* <span className="theme-color">Code 1xed</span> */}
                        </h2>
                        <span className="custom-heading-title mb-0">
                          {hospitalDetail[0]?.info?.Department}
                        </span>
                        <span className="section-border-bottom"></span>
                      </div>
                    </div>
                    <div className="row blog-list blog-list-style-1 br-style">
                      <div className="blog-inner">
                        <div className="media doctor_detail_img ">
                          <div className="row w-100">
                            <div className="col-4">
                              {" "}
                              <div
                                className="blog-thumb relative"
                                style={{
                                  maxWidth: "100%",
                                }}
                              >
                                <img
                                  src={`data:image/jpeg;base64,${hospitalDetail[0]?.info?.profile_pic}`}
                                  className="img-fluid rounded m-0"
                                  width="170"
                                  height="130"
                                  alt="blog-img"
                                />
                              </div>
                            </div>
                            <div className="col-8">
                              {" "}
                              <div>
                                {selectedHospital && (
                                  <div className="row my-2">
                                    <div className="col-lg-6 mb-2">
                                      <p className="mb-0 fw-bold">Fee</p>
                                      <span>
                                        {selectedHospital?.value?.info?.fee ||
                                          "--"}
                                      </span>
                                    </div>
                                    <div className="col-lg-6 mb-2">
                                      <p className="mb-0 fw-bold">Email</p>
                                      <span>
                                        {selectedHospital?.value?.info?.email ||
                                          "--"}
                                      </span>
                                    </div>
                                    <div className="col-lg-6 mb-2">
                                      <p className="mb-0 fw-bold">
                                        Contact Number
                                      </p>
                                      <span>
                                        {selectedHospital?.value?.info
                                          ?.contact_no || "--"}
                                      </span>
                                    </div>

                                    <div className="col-lg-6 mb-2">
                                      <p className="mb-0 fw-bold">Address</p>
                                      <span>
                                        {selectedHospital?.value?.info
                                          ?.address || "--"}
                                      </span>
                                    </div>

                                    <div className="col-lg-6"></div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          {/* <div className="media-body">
                          <div className="top-meta"></div>
                          <div className="blog-title">
                            <h5 className="fs-18">
                              {hospitalDetail[0]?.info?.qualification}
                            </h5>
                          </div>
                          <div className="entry-content">
                            <p className="mb-0">
                              {hospitalDetail[0]?.info?.short_description}
                            </p>
                          </div>
                        </div> */}
                        </div>
                      </div>
                    </div>
                    <h4 className="title mb-0 mt-4">Appointment Schedules</h4>
                    {hospitalDetail?.length === 0 ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <img
                          style={{ width: "500px", textAlign: "center" }}
                          src="/assets/img/search.png"
                          alt="data"
                        />
                        <h2 className=" text-center mt-3">
                          Website Under Construction
                        </h2>
                      </div>
                    ) : (
                      <div className="">
                        <label className="cs_input_label cs_heading_color">
                          Please Select Hospital{" "}
                          <span className="asterisk">*</span>
                        </label>

                        {hospitalDetail?.length > 1 && (
                          <Select
                            value={selectedHospital}
                            options={hospitalDetail.map((hospital) => ({
                              label: hospital.info.title,
                              value: hospital,
                            }))}
                            onChange={handleHospitalChange}
                            placeholder="Select Hospital"
                            className="appointmentform_select"
                          />
                        )}
                        <div className="my-5"></div>
                        <div className="appointment-schedule">
                          {selectedHospital && (
                            <Table striped bordered rounded hover>
                              <thead>
                                <tr>
                                  <th>Day</th>
                                  <th>Morning</th>
                                  <th>Evening</th>
                                </tr>
                              </thead>
                              <tbody>
                                {Object.keys(
                                  selectedHospital.value.schedule
                                ).map((day) => (
                                  <tr key={day}>
                                    <td>{day}</td>
                                    <td>
                                      {selectedHospital.value.schedule[day]
                                        ?.Morning ? (
                                        <span>
                                          <i className="fa-regular fa-clock"></i>{" "}
                                          {selectedHospital.value.schedule[day]
                                            .Morning.Start === "On Call" ||
                                          selectedHospital.value.schedule[day]
                                            .Morning.End === "On Call"
                                            ? "On Call"
                                            : `${formatTime(
                                                selectedHospital.value.schedule[
                                                  day
                                                ].Morning.Start
                                              )} - ${formatTime(
                                                selectedHospital.value.schedule[
                                                  day
                                                ].Morning.End
                                              )}`}
                                        </span>
                                      ) : (
                                        <span>
                                          <i className="fa-regular fa-clock mr-1"></i>{" "}
                                          N/A
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {selectedHospital.value.schedule[day]
                                        ?.Evening ? (
                                        <span>
                                          <i className="fa-regular fa-clock"></i>{" "}
                                          {selectedHospital.value.schedule[day]
                                            .Evening.Start === "On Call" ||
                                          selectedHospital.value.schedule[day]
                                            .Evening.End === "On Call"
                                            ? "On Call"
                                            : `${formatTime(
                                                selectedHospital.value.schedule[
                                                  day
                                                ].Evening.Start
                                              )} - ${formatTime(
                                                selectedHospital.value.schedule[
                                                  day
                                                ].Evening.End
                                              )}`}
                                        </span>
                                      ) : (
                                        <span>
                                          <i className="fa-regular fa-clock mr-1"></i>{" "}
                                          N/A
                                        </span>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                            // <div className="col-lg-12 appointment-schedule pl-0 pr-0">
                            //   <table className="table ">
                            //     <thead>
                            //       <tr>
                            //         <th>Day</th>
                            //         <th>Morning</th>
                            //         <th>Evening</th>
                            //       </tr>
                            //     </thead>
                            //     <tbody>
                            //       {Object.keys(selectedHospital.value.schedule).map(
                            //         (day) => (
                            //           <tr key={day}>
                            //             <td>{day}</td>
                            //             <td>
                            //               {selectedHospital.value.schedule[day]
                            //                 ?.Morning ? (
                            //                 <span>
                            //                   <i className="fa-regular fa-clock"></i>{" "}
                            //                   {selectedHospital.value.schedule[day]
                            //                     .Morning.Start === "On Call" ||
                            //                   selectedHospital.value.schedule[day]
                            //                     .Morning.End === "On Call"
                            //                     ? "On Call"
                            //                     : `${formatTime(
                            //                         selectedHospital.value.schedule[
                            //                           day
                            //                         ].Morning.Start
                            //                       )} - ${formatTime(
                            //                         selectedHospital.value.schedule[
                            //                           day
                            //                         ].Morning.End
                            //                       )}`}
                            //                 </span>
                            //               ) : (
                            //                 <span>
                            //                   <i className="fa-regular fa-clock mr-1"></i>{" "}
                            //                   N/A
                            //                 </span>
                            //               )}
                            //             </td>
                            //             <td>
                            //               {selectedHospital.value.schedule[day]
                            //                 ?.Evening ? (
                            //                 <span>
                            //                   <i className="fa-regular fa-clock"></i>{" "}
                            //                   {selectedHospital.value.schedule[day]
                            //                     .Evening.Start === "On Call" ||
                            //                   selectedHospital.value.schedule[day]
                            //                     .Evening.End === "On Call"
                            //                     ? "On Call"
                            //                     : `${formatTime(
                            //                         selectedHospital.value.schedule[
                            //                           day
                            //                         ].Evening.Start
                            //                       )} - ${formatTime(
                            //                         selectedHospital.value.schedule[
                            //                           day
                            //                         ].Evening.End
                            //                       )}`}
                            //                 </span>
                            //               ) : (
                            //                 <span>
                            //                   <i className="fa-regular fa-clock mr-1"></i>{" "}
                            //                   N/A
                            //                 </span>
                            //               )}
                            //             </td>
                            //           </tr>
                            //         )
                            //       )}
                            //     </tbody>
                            //   </table>
                            // </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="col-lg-6 mb-3 mb-lg-0">
                    <div className="title-wrap">
                      <div className="section-title">
                        <h2 className="title mb-0">Book An Appointment</h2>
                        <span className="section-border-bottom"></span>
                      </div>
                    </div>
                    <form className="row">
                      <div className="col-lg-6 mb-4">
                        <label className="cs_input_label cs_heading_color">
                          Patient Name <span className="asterisk">*</span>
                        </label>
                        <input
                          name="patient_name"
                          value={inputValues.patient_name}
                          type="text"
                          className="appointmentform_input"
                          placeholder="Enter Patient Name"
                          onChange={handleAllInputChange}
                        />
                        <div className="cs_height_42 cs_height_xl_25"></div>
                      </div>
                      <div className="col-lg-6 mb-4">
                        <label className="cs_input_label cs_heading_color">
                          Please Select Gender{" "}
                          <span className="asterisk">*</span>
                        </label>
                        <Select
                          // name="gender"
                          className="appointmentform_select"
                          value={gender?.value}
                          options={genderOptions}
                          onChange={handleGenderChange}
                          placeholder="Select Gender"
                        />

                        <div className="cs_height_42 cs_height_xl_25"></div>
                      </div>
                      <div className="col-lg-6 mb-4">
                        <label className="cs_input_label cs_heading_color">
                          Please Select Relation
                        </label>
                        <Select
                          className="appointmentform_select"
                          value={relation?.value}
                          options={reasonOptions}
                          onChange={handleReasonChange}
                          placeholder="Select Relation"
                        />

                        <div className="cs_height_42 cs_height_xl_25"></div>
                      </div>
                      <div className="col-lg-6 mb-4">
                        <label className="cs_input_label cs_heading_color">
                          Relation Name
                        </label>
                        <input
                          name="relation_name"
                          value={inputValues.relation_name}
                          type="text"
                          className="appointmentform_input"
                          placeholder="Enter Relation Name"
                          onChange={handleAllInputChange}
                        />
                        <div className="cs_height_42 cs_height_xl_25"></div>
                      </div>

                      <div className="col-lg-6 mb-4">
                        <label className="cs_input_label cs_heading_color">
                          Email
                        </label>
                        <input
                          name="email"
                          type="email"
                          value={inputValues.email}
                          className="appointmentform_input"
                          placeholder="Enter Your Email"
                          onChange={handleAllInputChange}
                        />
                        <div className="cs_height_42 cs_height_xl_25"></div>
                      </div>
                      <div className="col-lg-6 mb-4">
                        <label className="cs_input_label cs_heading_color">
                          Contact Number <span className="asterisk">*</span>
                        </label>
                        <input
                          name="contact_number"
                          value={inputValues.contact_number}
                          type="number"
                          className="appointmentform_input"
                          placeholder="Enter Contact Number"
                          onChange={handleContactNumber}
                          maxLength={14}
                          minLength={10}
                        />
                        <div className="cs_height_42 cs_height_xl_25"></div>
                      </div>

                      <div className="col-lg-6 mb-4">
                        <label className="cs_input_label cs_heading_color">
                          Preferred Date <span className="asterisk">*</span>
                        </label>
                        <div className="cs_with_icon_input">
                          <input
                            name="appointment_date"
                            type="date"
                            value={inputValues.appointment_date}
                            className="appointmentform_input"
                            id="datepicker"
                            maxLength={10}
                            placeholder="August 24, 2023"
                            onChange={handleAllInputChange}
                            min={todayDate}
                          />
                        </div>
                        <div className="cs_height_42 cs_height_xl_25"></div>
                      </div>

                      <div className="col-lg-6 mb-4">
                        <label className="cs_input_label cs_heading_color">
                          Preferred Time <span className="asterisk">*</span>
                        </label>
                        <Select
                          className="appointmentform_select"
                          value={
                            displayedValue !== null
                              ? {
                                  value: hospitalSlots?.value,
                                  label: displayedValue,
                                }
                              : null
                          }
                          options={shiftOptions}
                          onChange={handleShiftChange}
                          placeholder={
                            inputValues.appointment_date
                              ? "Select Time"
                              : "Please Select Date First"
                          }
                        />

                        <div className="cs_height_42 cs_height_xl_25"></div>
                      </div>

                      <div className="col-lg-6 mb-4">
                        <label className="cs_input_label cs_heading_color">
                          Age
                        </label>
                        <input
                          value={inputValues.age}
                          name="age"
                          type="number"
                          className="appointmentform_input"
                          placeholder="Enter Your Age"
                          onChange={handleAllInputChange}
                        />
                        <div className="cs_height_42 cs_height_xl_25"></div>
                      </div>

                      <div className="col-lg-6 mb-4">
                        <label className="cs_input_label cs_heading_color">
                          Captcha
                        </label>
                        <Captcha
                          captcha={captcha}
                          inputCaptcha={input_captcha}
                          textColorClass={textColorClass}
                          checkCaptcha={check_captcha}
                          handleInputChange={handleInputChange}
                        />
                      </div>

                      <div className="col-lg-12 mb-4">
                        <label className="cs_input_label cs_heading_color">
                          Remarks
                        </label>
                        <textarea
                          rows={5}
                          name="remarks"
                          type="number"
                          value={inputValues.remarks}
                          className="appointmentform_input"
                          placeholder="Write Your Remarks Here........."
                          onChange={handleAllInputChange}
                        ></textarea>
                        <div className="cs_height_45 cs_height_xl_25"></div>
                      </div>

                      <div className="col-lg-12 theme_submit_btn_wrapper  mb-4">
                        <button
                          className="theme_submit_btn"
                          type="submit"
                          onClick={handleSubmit}
                          disabled={loading}
                        >
                          <span>
                            <span>{loading ? "Please wait" : "Submit"}</span>
                          </span>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>

        <FooterWrapper />
      </div>
    </div>
  );
}

export default HospitalClinic;
