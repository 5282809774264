import React, { useEffect } from "react";
// import "./../Theme2Components/theme2.css";
// import HomeServices from "./HomeServices";
// import LatestWorks from "./LatestWorks";
// import HomeFeatures from "./HomeFeatures";
// import HomeAchievement from "./HomeAchievement";
// import OurClients from "./OurClients";
// import FirstIntroduction from "./IntroductionSections/FirstIntroduction";
// import SecondIntroduction from "./IntroductionSections/SecondIntroduction";
// import ThirdIntroduction from "./IntroductionSections/ThirdIntroduction";
// import FifthIntroduction from "./IntroductionSections/FifthIntroduction";
// import FourthIntroduction from "./IntroductionSections/FourthIntroduction";
// import FirstFeaturedServices from "./Services/FirstFeaturedServices";
import SecondFeaturedServices from "./Services/SecondFeaturedServices";
import ThirdFeaturedServices from "./Services/ThirdFeaturedServices";
import FirstSlider from "./HeaderSliders/FirstSlider";
import FirstFacilities from "./HomeFacilities/FirstFacilities";
import { useSelector } from "react-redux";
// import SecondFacilities from "./HomeFacilities/SecondFacilities";
import CeoMessage from "./CeoMessage";
import HomeNews from "./NewsUpdates/HomeNews";
import HomeReviews from "./Reviews/HomeReviews";
import HomeIntro from "./HomeIntro";
import SecondSlider from "./HeaderSliders/SecondSlider";
import ThirdMainSlider from "./HeaderSliders/ThirdMainSlider";
import FourthBannerSlider from "./HeaderSliders/FourthBannerSlider";
import HeaderWrapper from "./Header/HeaderWrapper";
import FooterWrapper from "./Footer/FooterWrapper";
import MeetDoctors from "./MeetDoctors";
import { Link } from "react-router-dom";
import DayCounter from "./DayCounter";
import VideoBgSection from "./Home/VideoBgSection";
// import FifthBannerSlider from "./HeaderSliders/FifthBannerSlider";

export default function Home({ isData, setIsdata }) {
  const sitedata = useSelector((store) => store.global.loginData);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="page-wrapper-inner">
      <div className="page-wrapper-inner">
        <HeaderWrapper />

        {sitedata?.options?.banner_style === "animation" ? (
          <SecondSlider />
        ) : sitedata?.options?.banner_style === "version3" ? (
          <ThirdMainSlider />
        ) : sitedata?.options?.banner_style === "version4" ? (
          <FourthBannerSlider />
        ) : (
          <FirstSlider />
        )}
        {/* <FifthBannerSlider /> */}

        <HomeIntro />
        {/* <SecondSlider setIsdata={setIsdata} /> */}
        {/* <FirstIntroduction /> */}
        {/* <SecondIntroduction /> */}
        {/* <ThirdIntroduction/> */}
        {/* <FourthIntroduction /> */}
        {/* <FifthIntroduction /> */}

        {sitedata?.theme_service === "version_1" ? (
          // <FirstFeaturedServices setIsdata={setIsdata} />
          <SecondFeaturedServices setIsdata={setIsdata} />
        ) : (
          <ThirdFeaturedServices setIsdata={setIsdata} />
        )}

        {sitedata?.site_type === "hospitals" && <FirstFacilities />}
        <HomeNews />
        {/* {sitedata?.site_type === "hospitals" && <SecondFacilities />} */}

        <CeoMessage />
        <MeetDoctors />
        <section
          id="get-quote-section"
          className="get-quote-section bg-theme pad-top-40 pad-bottom-50 relative index-1"
        >
          <div className="container">
            <div className="row get-quote-3">
              <div className="col-lg-12">
                <div className="title-wrap mb-0">
                  <div className="section-title text-center mb-3">
                    <h3 className="title text-uppercase home-discount-offer">
                      {sitedata?.advertisement?.short_detail_for_button ||
                        "50% off !! Come With OurOffer Coupons And Refer Friends!!"}
                    </h3>
                  </div>
                </div>
                <div className="button justify-content-center d-flex">
                  <Link
                    to={sitedata?.advertisement?.button_url || "#"}
                    className="btn btn-border ms-3"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Download Now"
                  >
                    {sitedata?.advertisement?.button_name || "Book Now"}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <DayCounter />
        {sitedata?.permissions?.review !== "hide" ? <HomeReviews /> : <div className="mt-5"></div>}

        {/* <HomeAchievement /> */}
        {/* <HomeFeatures /> */}
        {/* <LatestWorks /> */}
        {/* <HomeServices /> */}
        {/* <OurClients /> */}

        <VideoBgSection />

        <FooterWrapper />
      </div>
    </div>
  );
}
