

import React, { useState } from 'react';


function limitParagraphByCharLimit(paragraph, charLimit) {
    if (!paragraph || charLimit <= 0) {
        return '';
    }
    let truncated = paragraph.slice(0, charLimit);
    const lastSpacePos = truncated.lastIndexOf(' ');
    if (lastSpacePos === -1) {
        return truncated;
    }
    return truncated.slice(0, lastSpacePos);
}
const ParagraphLimiter = () => {
    const [charLimit, setCharLimit] = useState(60);
    const [paragraph, setParagraph] = useState("This is a sample paragraph that will be limited by character count. The goal is to cut it at the last space within the character limit.");
    return (
        <div>
            <h1>Paragraph Limiter</h1>
            <p>{limitParagraphByCharLimit(paragraph, charLimit)}</p>
            <label>
                Set Character Limit:
                <input
                    type="number"
                    value={charLimit}
                    onChange={(e) => setCharLimit(Number(e.target.value))}
                    min="1"
                />
            </label>
        </div>
    );
};
export default ParagraphLimiter;