import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useSelector } from "react-redux";
import HeaderWrapper from "../Header/HeaderWrapper";
import FooterWrapper from "../Footer/FooterWrapper";
import { capitalize } from "@mui/material";
import { SendPostRequest } from "../../http";

const teamData = [
  {
    id: 1,
    name: "Dr.Oviya Ben",
    designation: "Dentist",
    image: "/assets/images/team/1.jpg",
    social: {
      facebook: "#",
      twitter: "#",
      instagram: "#",
      linkedin: "#",
    },
  },
  {
    id: 2,
    name: "Dr.Sno White",
    designation: "Psychologist",
    image: "/assets/images/team/medical/2.jpg",
    social: {
      facebook: "#",
      twitter: "#",
      instagram: "#",
      linkedin: "#",
    },
  },
  {
    id: 3,
    name: "Dr.Ken Morgan",
    designation: "Cardiologist",
    image: "/assets/images/team/medical/3.jpg",
    social: {
      facebook: "#",
      twitter: "#",
      instagram: "#",
      linkedin: "#",
    },
  },
  {
    id: 4,
    name: "Dr.Aimee Devlin",
    designation: "Surgeon",
    image: "/assets/images/team/medical/4.jpg",
    social: {
      facebook: "#",
      twitter: "#",
      instagram: "#",
      linkedin: "#",
    },
  },
  {
    id: 5,
    name: "Dr.Rashed Chotta",
    designation: "Ophthalmologist",
    image: "/assets/images/team/medical/5.jpg",
    social: {
      facebook: "#",
      twitter: "#",
      instagram: "#",
      linkedin: "#",
    },
  },
];

function AboutMe() {
  const sitedata = useSelector((store) => store.global.loginData);
  const owlOptions = {
    loop: true,
    nav: false,
    dots: false,
    autoplay: true,
    autoplayTimeout: 2500,
    autoplayHoverPause: true,
    smartSpeed: 1000,
    margin: 30,
    responsive: {
      0: { items: 1 },
      600: { items: 2 },
      1000: { items: 3 },
    },
  };

  const [doctorAbout, setDoctorAbout] = useState([]);
  const [activeAwardIndex, setActiveAwardIndex] = useState(null);
  const [activeEducationIndex, setActiveEducationIndex] = useState(null);
  const [activePostGraduationIndex, setActivePostGraduationIndex] =
    useState(null);
  const [activeExperienceIndex, setActiveExperienceIndex] = useState(null);

  const toggleAccordion = (section, index) => {
    switch (section) {
      case "award":
        setActiveAwardIndex((prevIndex) =>
          prevIndex === index ? null : index
        );
        break;
      case "education":
        setActiveEducationIndex((prevIndex) =>
          prevIndex === index ? null : index
        );
        break;
      case "postgraduation":
        setActivePostGraduationIndex((prevIndex) =>
          prevIndex === index ? null : index
        );
        break;
      case "experience":
        setActiveExperienceIndex((prevIndex) =>
          prevIndex === index ? null : index
        );
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const title = sitedata?.site_title || "";
    document.title = capitalize(title) + " :: About - ClinTa";
    window.scrollTo(0, 0);
    getDoctorAboutData();
  }, []);

  const getDoctorAboutData = async () => {
    const response = await SendPostRequest("content/about");
    setDoctorAbout(response.data);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="page-wrapper-inner">
      <div class="page-wrapper-inner">
        <HeaderWrapper />
        <div class="page-title-wrap typo-white mb-0">
          <div
            class="page-title-wrap-inner section-bg-img"
            style={{
              backgroundImage: "url(/assets/images/bg/page-title-bg.jpg)",
            }}
          >
            p<span class="black-overlay"></span>
            <div class="container">
              <div class="row text-left">
                <div class="col-md-12">
                  <div class="page-title-inner">
                    {/* <h1 class="page-title mb-0">About Us</h1> */}
                    <h1 class="page-title mb-0">
                      {" "}
                      Dr. {doctorAbout?.name || "About Us"}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section id="feature-section" className="feature-section py-5">
          <div className="container">
            <div className="row mb-4">
              <div className="col-lg-6">
                <div className="title-wrap">
                  <div className="section-title ">
                    <h2 className="section-title mb-0 text-uppercase">
                      About <span className="theme-color">Me</span>
                    </h2>
                    <span className="section-border-bottom"></span>
                  </div>
                </div>
                <div className="pad-top-15">
                  <p
                    className="margin-bottom-20 text-justify"
                    dangerouslySetInnerHTML={{
                      __html: doctorAbout?.short_description
                        ? doctorAbout?.short_description
                        : "Coming soon! Awaited content to be revealed shortly.",
                    }}
                  />
                </div>
                <div className="title-wrap my-3">
                  <div className="section-title mb-0">
                    <h5 className="section-title mb-0 text-uppercase">
                      AWARDS<span className="theme-color"> AND </span>
                      MEMBERSHIPS
                    </h5>
                    {/* <span className="section-border-bottom"></span> */}
                  </div>
                </div>

                <div className="accordion model-2" id="accordion2">
                  {doctorAbout.award_membership &&
                  doctorAbout.award_membership.length > 0 ? (
                    doctorAbout.award_membership.map((item, index) => (
                      <div className="accordion-item about-me-accodian" key={index}>
                        <h2 className="accordion-header" id={`heading${index}`}>
                          <button
                            className={`accordion-button ${
                              activeAwardIndex === index ? "" : "collapsed"
                            }`}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse${index}`}
                            aria-expanded={activeAwardIndex === index}
                            aria-controls={`collapse${index}`}
                            onClick={() => toggleAccordion(index)}
                          >
                            {item?.Title}
                          </button>
                        </h2>
                        <div
                          id={`collapse${index}`}
                          className={`accordion-collapse collapse ${
                            activeAwardIndex === index ? "show" : ""
                          }`}
                          aria-labelledby={`heading${index}`}
                          data-bs-parent="#accordion2"
                        >
                          <div className="accordion-body">
                            <div className="doctor-graduation-content">
                              <h5>Year</h5>
                              <p>{item?.Year}</p>
                            </div>
                            <p>{item?.Description}</p>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>Coming soon! Awaited content to be revealed shortly.</p>
                  )}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="title-wrap">
                  <div className="section-title ">
                    <h2 className="section-title mb-0 text-uppercase">
                      Education <span className="theme-color"></span>
                    </h2>
                    <span className="section-border-bottom"></span>
                  </div>
                </div>
                <div className="title-wrap my-3">
                  <div className="section-title mb-0 ">
                    <h5 className="section-title mb-0 text-uppercase">
                      Graduation <span className="theme-color"></span>
                    </h5>
                  </div>
                </div>

                <div className="accordion model-2" id="educationAccordion">
                  {doctorAbout?.graduation &&
                  doctorAbout?.graduation.length > 0 ? (
                    doctorAbout.graduation.map((item, index) => (
                      <div className="accordion-item about-me-accodian" key={index}>
                        <h2
                          className="accordion-header"
                          id={`educationHeading${index}`}
                        >
                          <button
                            className={`accordion-button ${
                              activeEducationIndex === index ? "" : "collapsed"
                            }`}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#educationCollapse${index}`}
                            aria-expanded={activeEducationIndex === index}
                            aria-controls={`educationCollapse${index}`}
                            onClick={() => toggleAccordion(index)}
                          >
                            {item?.Title}
                          </button>
                        </h2>
                        <div
                          id={`educationCollapse${index}`}
                          className={`accordion-collapse collapse ${
                            activeEducationIndex === index ? "show" : ""
                          }`}
                          aria-labelledby={`educationHeading${index}`}
                          data-bs-parent="#educationAccordion"
                        >
                          <div className="accordion-body">
                            <p className="mb-2">{item?.Description}</p>
                            <div className="doctor-graduation-content">
                              <h5>Institute</h5>
                              <p>{item?.Institute}</p>
                            </div>
                            <div className="doctor-graduation-content">
                              <h5>City</h5>
                              <p>{item?.City}</p>
                            </div>
                            <div className="doctor-graduation-content">
                              <h5>Year</h5>
                              <p>{item?.Year}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>Coming soon! Awaited content to be revealed shortly.</p>
                  )}
                </div>

                <div className="title-wrap my-3">
                  <div className="section-title mb-0 ">
                    <h5 className="section-title mb-0 text-uppercase">
                      Post Graduation <span className="theme-color"></span>
                    </h5>
                  </div>
                </div>

                <div className="accordion model-2" id="postGraduationAccordion">
                  {doctorAbout?.post_graduation &&
                  doctorAbout?.post_graduation.length > 0 ? (
                    doctorAbout.post_graduation.map((item, index) => (
                      <div className="accordion-item about-me-accodian" key={index}>
                        <h2
                          className="accordion-header"
                          id={`postGraduationHeading${index}`}
                        >
                          <button
                            className={`accordion-button ${
                              activePostGraduationIndex === index
                                ? ""
                                : "collapsed"
                            }`}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#postGraduationCollapse${index}`}
                            aria-expanded={activePostGraduationIndex === index}
                            aria-controls={`postGraduationCollapse${index}`}
                            onClick={() => toggleAccordion(index)}
                          >
                            {item?.Title}
                          </button>
                        </h2>
                        <div
                          id={`postGraduationCollapse${index}`}
                          className={`accordion-collapse collapse ${
                            activePostGraduationIndex === index ? "show" : ""
                          }`}
                          aria-labelledby={`postGraduationHeading${index}`}
                          data-bs-parent="#postGraduationAccordion"
                        >
                          <div className="accordion-body">
                            <p className="mb-2">{item?.Description}</p>
                            <div className="doctor-graduation-content">
                              <h5>Speciality</h5>
                              <p>{item?.Speciality}</p>
                            </div>
                            <div className="doctor-graduation-content">
                              <h5>Institute</h5>
                              <p>{item?.Institute}</p>
                            </div>
                            <div className="doctor-graduation-content">
                              <h5>City</h5>
                              <p>{item?.City}</p>
                            </div>
                            <div className="doctor-graduation-content">
                              <h5>Year</h5>
                              <p>{item?.Year}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>Coming soon! Awaited content to be revealed shortly.</p>
                  )}
                </div>

                <div className="title-wrap my-3">
                  <div className="section-title mb-0 ">
                    <h5 className="section-title mb-0 text-uppercase">
                      Experience <span className="theme-color"></span>
                    </h5>
                  </div>
                </div>

                <div className="accordion model-2" id="experienceAccordion">
                  {doctorAbout?.experience &&
                  doctorAbout?.experience.length > 0 ? (
                    doctorAbout.experience.map((item, index) => (
                      <div className="accordion-item about-me-accodian" key={index}>
                        <h2
                          className="accordion-header"
                          id={`experienceHeading${index}`}
                        >
                          <button
                            className={`accordion-button ${
                              activeExperienceIndex === index ? "" : "collapsed"
                            }`}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#experienceCollapse${index}`}
                            aria-expanded={activeExperienceIndex === index}
                            aria-controls={`experienceCollapse${index}`}
                            onClick={() => toggleAccordion(index)}
                          >
                            {item?.Title}
                          </button>
                        </h2>
                        <div
                          id={`experienceCollapse${index}`}
                          className={`accordion-collapse collapse ${
                            activeExperienceIndex === index ? "show" : ""
                          }`}
                          aria-labelledby={`experienceHeading${index}`}
                          data-bs-parent="#experienceAccordion"
                        >
                          <div className="accordion-body">
                            <p className="mb-2">{item?.Description}</p>
                            <div className="doctor-graduation-content">
                              <h5>Start Date</h5>
                              <p>{item?.StartDate}</p>
                            </div>
                            <div className="doctor-graduation-content">
                              <h5>End Date</h5>
                              <p>{item?.EndDate}</p>
                            </div>
                            <div className="doctor-graduation-content">
                              <h5>Institute</h5>
                              <p>{item?.Institute}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>Coming soon! Awaited content to be revealed shortly.</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section
          id="team-section"
          className="team-section pad-top-95 pad-bottom-90 section-bg-img"
          style={{ backgroundImage: `url('/assets/images/bg/bg-1.jpg')` }}
        >
          <div className="container">
            <div className="row">
              <div className="offset-md-2 col-md-8">
                <div className="title-wrap text-center">
                  <div className="section-title margin-bottom-60">
                    <h2 className="section-title margin-top-5 mb-0 typo-white text-uppercase">
                      Meet The <span className="theme-color">Team</span>
                    </h2>
                    <span className="section-border-bottom center"></span>
                  </div>
                </div>
              </div>
            </div>
            <OwlCarousel
              className="owl-theme team-main-wrapper typo-white"
              {...owlOptions}
            >
              {teamData.map((team) => (
                <div className="item" key={team.id}>
                  <div className="team-style-1">
                    <div className="team-inner margin-bottom-20">
                      <div className="team-thumb mb-0 relative">
                        <img
                          src={team.image}
                          className="img-fluid thumb w-100"
                          width="270"
                          height="270"
                          alt={team.name}
                        />
                      
                      </div>
                      <div className="team-details text-center pad-20">
                        <div className="team-name">
                          <h3 className="mb-0">
                            <a href="#" className="client-name">
                              {team.name}
                            </a>
                          </h3>
                        </div>
                        <div className="team-designation">
                          <p className="mb-0 theme-color">{team.designation}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </OwlCarousel>
          </div>
        </section> */}

        <FooterWrapper />
      </div>
    </div>
  );
}

export default AboutMe;
