import React, { useEffect, useState } from "react";
import { SendPostRequest } from "../http";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import { limitParagraphByCharLimit } from "../Helper/Helper";

function HomeIntro() {
  const [homeIntroData, setHomeIntroData] = useState({});

  useEffect(() => {
    getHomeIntroData();
  }, []);

  const getHomeIntroData = async () => {
    const response = await SendPostRequest("content/home");
    setHomeIntroData(response?.data);
  };

  const options = {
    loop: true,
    nav: false,
    dots: false,
    autoplay: true,
    autoplayTimeout: 2500,
    autoplayHoverPause: true,
    smartSpeed: 1000,
    margin: 30,
    responsive: {
      0: { items: 1 },
    },
  };

  return (
    <section id="section-about" className="section-about pt-5 pb-0 mb-0">
      <div className="container">
        <div className="row my-3">
          {/* <div className="col-lg-6 col-md-12 col-12">
            <div
              className="about-vedio-frame"
              dangerouslySetInnerHTML={{
                __html: homeIntroData?.video_link?.replace(
                  /src="([^"]+)"/,
                  (match, p1) => `src="${p1}&autoplay=1"`
                ),
              }}
            />
          </div> */}

          <div className="col-lg-6 col-md-12 col-12">
            <div
              className="about-vedio-frame"
              dangerouslySetInnerHTML={{
                __html: homeIntroData?.video_link?.replace(
                  /src="([^"]+)"/,
                  (match, p1) => {
                    const newUrl = new URL(p1);
                    newUrl.searchParams.set("autoplay", "1");
                    newUrl.searchParams.set("rel", "0");
                    return `src="${newUrl.toString()}"`;
                  }
                ),
              }}
            />
          </div>

          <div className="col-lg-6 col-md-12 col-12">
            <div className="title-wrap relative mb-3">
              <div className="section-title margin-bottom-25">
                <h2 className="section-title mb-0 text-uppercase">
                  {homeIntroData?.main_heading}
                </h2>
                <span className="section-border-bottom"></span>
              </div>
              <div className="pad-top-15">
                <p className="mb-0">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: homeIntroData?.short_summary,
                    }}
                  ></span>

                  {/* <span
                    dangerouslySetInnerHTML={{
                      __html:
                        limitParagraphByCharLimit(
                          homeIntroData?.short_summary,
                          610
                        ) + "... ",
                    }}
                  ></span>
                  {homeIntroData?.short_summary?.length > 610 && (
                    <Link
                      className="btn-link fw-bold text-decoration-none"
                      to={`/about`}
                    >
                      Read More
                    </Link>
                  )} */}
                </p>
              </div>
            </div>
          </div>

          <div className="home-intro-desktop mt-5">
            <div className="row ">
              {homeIntroData?.principles?.map((data, index) => (
                <div key={index} className="col-md-4">
                  <div className="feature-box-wrap f-box-style-1 relative ">
                    <div className="feature-box-details text-center">
                      <div className="feature-icon">
                        <img
                          src={data?.image}
                          alt=""
                          style={{ width: "40px", height: "40px" }}
                        />
                      </div>
                      <div className="feature-content">
                        <div className="feature-title relative margin-bottom-10">
                          <h5>{data?.heading}</h5>
                        </div>

                        <p className="mb-0">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: data?.description,
                            }}
                          ></span>
                        </p>

                        {/* <p className="mb-0">
                          <span
                            dangerouslySetInnerHTML={{
                              __html:
                                limitParagraphByCharLimit(
                                  data?.description,
                                  125
                                ) + "... ",
                            }}
                          ></span>

                          {data?.description?.length > 125 && (
                            <Link
                              className="btn-link fw-bold text-decoration-none "
                              to={`/about`}
                            >
                              Read More
                            </Link>
                          )}
                        </p> */}
                        {/* <p className="mb-0">
                            {data?.description
                              ? data.description
                                  .split(" ")
                                  .slice(0, 9)
                                  .join(" ") + "..."
                              : ""}
                              
                            <Link
                              className="btn-link fw-bold text-decoration-none"
                              to={`/about`}
                            >
                              Read More
                            </Link>
                          </p> */}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="home-intro-mobile-view">
            <div className="row ">
              <OwlCarousel className="service-main-wrapper" {...options}>
                {homeIntroData?.principles?.map((data, index) => (
                  <div key={index} className="item">
                    <div className="feature-box-wrap f-box-style-1 relative">
                      <div className="feature-box-details text-center py-2">
                        <div className="feature-icon">
                          <img
                            src={data?.image}
                            alt=""
                            style={{ width: "40px", height: "40px" }}
                          />
                        </div>
                        <div className="feature-content">
                          <div className="feature-title relative margin-bottom-10">
                            <h5>{data?.heading}</h5>
                          </div>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: data?.description,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HomeIntro;
